import Articles from '@/components/Articles'
import Layout from '@/components/Layout'
import React, { Component } from 'react'

export default class artiklar extends Component {
    render() {
        return (
            <Layout active={4} title="Artiklar" forcedFooter={true}>
                <Articles amount={null} bottom={false}/>
            </Layout>
        )
    }
}
